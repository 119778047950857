<script setup lang="ts">
import { setupPendo } from '@/util/pendoConfig'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import Postmate from 'postmate'
import { onMounted, ref, watchEffect } from 'vue-demi'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import useEmitter from './composition/useEventEmmiter'
import { useAffiliateManagerStore } from './store'
import { setSentryMeta } from './util/setupSentry'
const store = useAffiliateManagerStore()
const router = useRouter()
const i18n = useI18n()
const { emit } = useEmitter()

const authenticatedUser = ref(false)
onMounted(() => {
  // if (process.env.NODE_ENV === "development") {
  //   Postmate.debug = true;
  // }
  const handshake = new Postmate.Model({
    'updated-token': (token: string) => store.refreshToken(token),
    'change-route': async (data: any) => {
      store.setChangeRouteFlag(true)
      router.push(data)
    },
    'change-init-data': async (data: any) => {
      store.setInitDataUpdate(data)
    },
    'refresh-email-template-campaign': async () => {
      store.updateCampaignEmailTemplate()
    },
    previewUrl: (previewData: string) => store.setPreviewUrl(previewData),

    setMediaFile: (file: any) => {
      emit('selectedMediaFile', file)
    },
    closeMediaModal: () => {
      console.log('updateUI----came ro app vue')

      emit('updateUI')
    },
  })

  handshake.then(({ model }: any) => {
    let langCode
    if (model?.locale === 'pt_BR') {
      langCode = 'pr_BR'
    } else if (model?.locale === 'pt_PT') {
      langCode = 'pr_PT'
    } else {
      langCode = model?.locale
    }
    i18n.locale.value = langCode ? langCode.split('_').join('-') : 'en-US'
    store.init(model, handshake)
    store.getPreviewUrl()
    setSentryMeta({
      userId: model.userId,
      companyId: model.companyId,
      locationId: model.locationId,
      locale: i18n.locale.value,
    })
    setupPendo(model)
  })
})

watchEffect(() => {
  if (store.tokenId) {
    authenticatedUser.value = true
  }
})
</script>

<template>
  <UIContentWrap
    :full-screen="store.showFullScreen"
    :locale="i18n.locale.value"
  >
    <div
      v-if="store.locationId"
      class="app-container w-full h-full flex flex-col justify-start items-start"
    >
      <router-view></router-view>
    </div>
  </UIContentWrap>
</template>

<style>
#app {
  @apply bg-gray-100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
body {
  font-family: 'Inter';
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(209, 213, 219) !important;
  opacity: 1; /* Firefox */
}
.app-container {
  background: rgb(242 244 247 / var(--tw-bg-opacity));
}
</style>
