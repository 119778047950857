interface Config {
  mode: string
  restApiUrl: string
  affiliateUrl: string
  contactUrl: string
  previewUrl: string
  mediaUrl: string
  firestoreBucketUrl: string
  whiteLabelLocationId: string[]
  imageServer: string
}
const imageServerWhitelist = [
  'images.unsplash.com',
  'cdn.pixabay.com',
  'storage.googleapis.com',
  'firebasestorage.googleapis.com',
  'pixabay.com',
  'assets-staging.cdn.msgsndr.com',
  'staging.cdn.msgsndr.com',
  'staging.filesafe.space',
  'default.staging.filesafe.space',
  'assets.cdn.msgsndr.com',
  'cdn.msgsndr.com',
  'cdn.filesafe.space',
  'assets.cdn.filesafe.space',
  'img.youtube.com',
  'i.vimeocdn.com',
  'embed-ssl.wistia.com',
  'widgets.leadconnectorhq.com',
]

const config: { [key: string]: Config } = {
  development: {
    mode: 'dev',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    affiliateUrl: 'https://staging.backend.leadconnectorhq.com',
    contactUrl: 'https://staging.backend.leadconnectorhq.com/contacts',
    previewUrl: 'https://staging.gohighlevel.com',
    mediaUrl: 'https://hl-media-center.web.app',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    whiteLabelLocationId: ['all'],
    imageServer: 'https://images-staging.leadconnectorhq.com',
  },
  staging: {
    mode: 'staging',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    affiliateUrl: 'https://staging.backend.leadconnectorhq.com',
    contactUrl: 'https://staging.backend.leadconnectorhq.com/contacts',
    previewUrl:
      'https://affiliate-builder-dot-funnel-preview-dot-highlevel-staging.uc.r.appspot.com',
    mediaUrl: 'https://hl-media-center.web.app',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    whiteLabelLocationId: ['Lx1EI6YIgQYMQi0ytFXv', 'r47GTd22o4SobRRauMk5', 'jVFIxsMY19D94nOSIOEO'],
    imageServer: 'https://images-staging.leadconnectorhq.com',
  },
  production: {
    mode: 'production',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    affiliateUrl: 'https://backend.leadconnectorhq.com',
    contactUrl: 'https://backend.leadconnectorhq.com/contacts',
    previewUrl: 'https://app.leadconnectorhq.com',
    mediaUrl: 'https://media-center-prod.firebaseapp.com',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    whiteLabelLocationId: [
      'hk9aJw1NxnORXlvWt0Ch',
      'PckM5393o8V8VUL7PYcb',
      'dyKbBe6XZJBFUoxeQyQT',
    ],
    imageServer: 'https://images-staging.leadconnectorhq.com',
  },
}

const override = false

const overrideConfig = {}
const envConfig = config[process.env.NODE_ENV || 'production']

export default override
  ? { ...envConfig, ...overrideConfig, imageServerWhitelist }
  : { ...envConfig, imageServerWhitelist }
