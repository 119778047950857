// https://pinia.esm.dev/introduction.html#basic-example
import config from '@/config'
import { defineStore } from 'pinia'
import { updateParent } from '../util/updateParent'

// interface Store {
//   appHandshake: number
//   retryCount: number
//   timezone: string
//   tokenId: string
//   changeRoute: boolean
//   userId: string
//   userType: string
//   userRole: string
//   companyId: string
//   countryCode: string
//   locationId: string
//   hasAffiliate: boolean
//   hasCampaign: boolean
//   skipOnboarding: boolean
//   hasStripeConnected: boolean
//   isStripeConnecting: boolean
// }
export const useAffiliateManagerStore = defineStore('affiliateManageStore', {
  state: () => {
    return {
      appHandshake: null as any,
      retryCount: 0,
      updateEmailTemplateTriggered: 0,
      tokenId: (import.meta.env.VITE_TOKEN_ID as string) || '',
      timezone: (import.meta.env.VITE_TIMEZONE as string) || '',
      userId: (import.meta.env.VITE_USER_ID as string) || '',
      userType: (import.meta.env.VITE_USER_TYPE as string) || '',
      userRole: (import.meta.env.VITE_USER_ROLE as string) || '',
      companyId: (import.meta.env.VITE_COMPANY_ID as string) || '',
      countryCode: (import.meta.env.VITE_COUNTRY_CODE as string) || '',
      locationId: (import.meta.env.VITE_LOCATION_ID as string) || '',
      hasAffiliate: (import.meta.env.VITE_HAS_AFFILIATE as boolean) || false,
      hasCampaign: (import.meta.env.VITE_HAS_CAMPAIGN as boolean) || false,
      skipOnboarding:
        (import.meta.env.VITE_SKIP_ONBOARDING as boolean) || false,
      hasStripeConnected:
        (import.meta.env.VITE_HAS_STRIPE_CONNECTED as boolean) || false,
      isStripeConnecting:
        (import.meta.env.VITE_HAS_STRIPE_CONNECTED as boolean) || false,
      changeRoute: false,
      customAffiliateName: undefined,
      customTitleName: undefined,
      customPayoutName: undefined,
      customCampaignName: undefined,
      customMediaName: undefined,
      showAddProduct: false,
      showRefresh: false,
      showFullScreen: false,
      builderPreviewDomain: undefined,
      showLevelUpItem: false,
      campaignCount: 0,
      currency: 'USD',
    }
  },
  actions: {
    async init(payload: any, handshake: any) {
      this.setAppInitData(payload)
      this.setAppHandshake(handshake)
    },

    setAppInitData(payload: any) {
      this.userId = payload.userId
      this.userRole = payload.userRole
      this.userType = payload.userType
      this.companyId = payload.companyId
      this.countryCode = payload.countryCode
      this.locationId = payload.locationId
      this.hasAffiliate = payload.hasAffiliate
      this.hasCampaign = payload.hasCampaign
      this.hasStripeConnected = payload.hasStripeConnected
      this.skipOnboarding = payload.skipOnboarding
      this.tokenId = payload.token
      this.timezone = payload.timezone
      this.customAffiliateName = payload.customAffiliateName
      this.customTitleName = payload.customTitleName
      this.customPayoutName = payload.customPayoutName
      this.customCampaignName = payload.customCampaignName
      this.customMediaName = payload.customMediaName
      this.currency = payload?.locationCurrency || 'USD'
      this.showLevelUpItem =
        (payload.locationId &&
          config.whiteLabelLocationId.includes(payload.locationId)) ||
        config.whiteLabelLocationId[0] === 'all'
          ? true
          : false
    },
    setAppHandshake(payload: any) {
      this.appHandshake = payload
    },
    refreshToken(newToken: string) {
      this.tokenId = newToken
    },
    setChangeRouteFlag(flag: boolean) {
      this.changeRoute = flag
    },
    updateRetryCount(count: number) {
      this.retryCount = count
    },
    updateStripeConnecting(value: boolean) {
      this.isStripeConnecting = value
    },

    updateCampaignEmailTemplate() {
      this.updateEmailTemplateTriggered++
    },

    updateShowAddProduct(showAddProduct, showRefresh) {
      this.showAddProduct = showAddProduct
      this.showRefresh = showRefresh
    },
    setInitDataUpdate(payload: any) {
      if (Object.keys(payload).includes('hasStripeConnected')) {
        this.hasStripeConnected = payload.hasStripeConnected
        this.updateStripeConnecting(false)
      } else if (Object.keys(payload).includes('hasAffiliate')) {
        this.hasAffiliate = payload.hasAffiliate
      } else if (Object.keys(payload).includes('hasCampaign')) {
        this.hasCampaign = payload.hasCampaign
      } else if (Object.keys(payload).includes('skipOnboarding')) {
        this.skipOnboarding = payload.skipOnboarding
      }
    },
    submitFeedback() {
      updateParent('submit-feedback')
    },

    updateFullScreen(val: boolean) {
      this.showFullScreen = val
    },
    updateCampaignCount(val: number) {
      this.campaignCount = val
    },

    getPreviewUrl() {
      if (this.appHandshake) {
        this.appHandshake.then(parent => parent.emit('getPreviewUrl'))
      }
    },
    setPreviewUrl(preview) {
      this.builderPreviewDomain = preview
    },
  },
})
