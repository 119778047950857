interface IUpdateMeta {
  altId: string
  altType: string
  allowedExtensions: string[]
  maxUploadSize: any
  application: string // which app its used
  appState: string
  tokenId?: string
  apiKey?: string
  selfHosted?: boolean
  userPermission: { contentAIEnabled: boolean }
  features: { contentAI: boolean }
}
interface IUpdateFolders {
  currentPath: string
  fileList: []
}
export interface State {
  altId: string
  altType: string
  allowedExtensions: string[]
  maxUploadSize: MaxUploadSize
  application: string // which app its used
  appState: string
  fileList: []
  currentPath: string
  uploadQueue: FileUpload[]
  tokenId: string
  activeItem: any
  directoryRoute: any[]
  apiKey: string
  retryCount: number
  selfHosted: boolean
  contentAIEnable: boolean

  loading: boolean
  mediaModal: boolean
  activeCategory: string
  previewItem: any
  selectedItems: any
  isMultiSelectMode: boolean
  isDragStarted: boolean
  processingQueue: any[]
  campaignList: any[]
  isLoadingCampaignList: boolean
}
interface FileUpload {
  name: string
  id: string
  uploaded: boolean
  url?: string
}

interface MaxUploadSize {
  image: number
  document: number
  video: number
  audio: number
}

import { defineStore } from 'pinia'

export const useAffiliateManagerMediaStore = defineStore(
  'affiliateManagerMediaStore',
  {
    state: (): State => ({
      altId: '',
      altType: '',
      allowedExtensions: [],
      maxUploadSize: {
        image: 100000000,
        document: 100000000,
        video: 4000000000,
        audio: 100000000,
      },
      application: 'media-center',
      appState: '',
      fileList: [],
      currentPath: '',
      uploadQueue: [],
      activeItem: {},
      tokenId: '',
      directoryRoute: [],
      apiKey: '',
      retryCount: 0,
      selfHosted: false,
      contentAIEnable: false,
      loading: true,
      mediaModal: false,
      activeCategory: 'my_media',
      previewItem: {},
      selectedItems: [],
      isMultiSelectMode: false,
      isDragStarted: false,
      processingQueue: [],
      campaignList: [],
      isLoadingCampaignList: false,
    }),
    actions: {
      updateFilesList(payload: IUpdateFolders) {
        const { currentPath, fileList } = payload
        this.fileList = fileList
        this.currentPath = currentPath
      },

      addToUploadQueue(newItem: FileUpload) {
        this.uploadQueue.push(newItem)
      },

      updateUploadQueue(id: string, url: string) {
        const idxFile = this.uploadQueue.findIndex(
          (x: FileUpload) => x.id === id
        )
        this.uploadQueue[idxFile].uploaded = true
        this.uploadQueue[idxFile].url = url
      },

      cleanUploadQueueHistory() {
        this.uploadQueue = this.uploadQueue.filter(x => !x.uploaded)
      },

      updateActiveObject(item: any) {
        if (this.activeItem?._id === item._id) {
          this.activeItem = {}
          return
        }
        this.activeItem = item
      },

      updateActiveExternalObject(item: any) {
        if (this.activeItem?._id === item.id) {
          this.activeItem = {}
          return
        }
        this.activeItem = item
        this.activeItem._id = item.id
      },

      updateDirectory(item: any) {
        if (item) this.directoryRoute.push(item)
      },
      emptyDirectory() {
        this.directoryRoute = []
      },
      updateMetaInfo(param: IUpdateMeta) {
        this.altId = param.altId
        this.altType = param.altType
        this.allowedExtensions = param.allowedExtensions
        this.application = param.application
        this.maxUploadSize = param.maxUploadSize
        this.appState = param.appState
        this.tokenId = param.tokenId || ''
        this.apiKey = param.apiKey || ''
        this.selfHosted = param.selfHosted || false
        this.contentAIEnable =
          param?.userPermission?.contentAIEnabled && param?.features?.contentAI
      },
      updateRetryCount(count: number) {
        this.retryCount = count
      },
      updateToken(token: string) {
        this.tokenId = token
      },

      updateActiveCategory(payload: string) {
        this.activeCategory = payload
      },
      updatePreviewItem(payload: any) {
        this.previewItem = payload
      },
      setSelectedItems(payload: any) {
        this.selectedItems = payload
        if (this.selectedItems.length > 0) this.isMultiSelectMode = true
        else this.isMultiSelectMode = false
      },
      resetSelectedItems() {
        this.selectedItems = []
        this.isMultiSelectMode = false
      },
      setProcessingQueue(payload: any) {
        this.processingQueue = payload
      },

      updateCampaignList(campaignList: any[]) {
        this.campaignList = campaignList
        this.isLoadingCampaignList = false
      },

      setLoadingCampaignList(loading: boolean) {
        this.isLoadingCampaignList = loading
      },
      updateProcessingQueue({
        _id,
        status,
        processed,
      }: {
        _id: string
        status: string
        processed: boolean
      }) {
        const fileIndex = this.processingQueue.findIndex(
          (x: any) => x._id === _id
        )
        if (fileIndex >= 0) {
          this.processingQueue[fileIndex].processed = processed
          this.processingQueue[fileIndex].status = status
        }
      },
    },
  }
)
